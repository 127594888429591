html {
	background: darken($red, 10%);
}
body {
	background: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}
header {
	z-index: 1;
	position: relative;
	width: 100%;
	box-shadow: 0 4px 6px #e8e7e799;
}
.tm-main {
	z-index: 0;
}
footer {
	padding-top: 25px;
    .tm-copyright {
        font-family: $alternatve-font-family;
        text-transform: uppercase;;
    }
}
.tm-shift-left-m {
	-ms-flex-order: 0;
    order: 0;
    @media (min-width: $breakpoint-medium) {
        -ms-flex-order: -1;
    	order: -1;
    }
}
.tm-shift-left {
	-ms-flex-order: -1;
    order: -1;
    @media (min-width: $breakpoint-medium) {
    	-ms-flex-order: 0;
    	order: 0;
    }
}

.tm-historic-listing {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-top: 180px;
}
.controls {
    background: $smoke;
    color: $gray;
    padding: 8px 14px 4px 14px;
    border-radius: 4px;
    margin: 0;
    &:hover {
        background: $red;
        color: $white;
    }
    .active & {
        background: $red;
        color: $white;
    }
}
.tm-shadow {
    box-shadow: 0 0 4px #e8e7e799;
}