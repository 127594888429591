.tm-carousel, .tm-banner {
    h1 {
        font-size: 2.2em;
        letter-spacing: 0.5px;
        line-height: 1.2;
        font-weight: 400;
        text-shadow: 0px 1px 7px rgba(0,0,0,1);
        @media screen and (min-width: $breakpoint-medium) {
            font-size: 3.8em;
        }
    }
    p {
        font-weight: 400;
        font-size: 0.9em;
        line-height: 1.5;
        text-shadow: 0px 2px 4px rgba(0,0,0,1);
        @media screen and (min-width: $breakpoint-medium) {
            font-size: 1.1em;
        }
    }
    .uk-list {
        text-shadow: 0px 2px 4px rgba(0,0,0,1);
    }
}
.tm-banner-container {
    margin:0;
    font-size: 8px;
    min-height: 340px;
    @media screen and (min-width: $breakpoint-medium) {
        margin-top: 46px;
        margin-bottom: 0;
        font-size: 16px;
    }
    svg {
        max-width: 80%;
        width: auto;
        max-height: 220px;
        @media screen and (min-width: $breakpoint-medium) {
            max-height: 360px;
        }
    }
    .uk-button {
        padding: 8px 24px;
        font-size: 1.8em;
        @media screen and (min-width: $breakpoint-medium) {
            padding: 12px 30px;
            font-size: 1.2em;
        }
    }
}