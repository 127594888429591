// Primary Colors
$red: #8b2f21;
$yellow: #dc9735;

// Muted/Utility Colors
$charcoal: #343334;
$gray: #848484;
$text-meta-color: #aaaaaa;
$smoke: #F5F5F5;
$iconInactive: #D7D8D9;
$white: #ffffff;
$black: #000000;
$inverse-global-color: $white;
$global-inverse-color: $white;
$shadow: #e8e7e7;

// Typography
$global-font-family: proxima-nova, sans-serif;;
$base-heading-font-family: 'besley', serif;
$alternatve-font-family: 'balboa', sans-serif;
$global-font-size: 16px;
$global-color: $gray;
$base-body-color: $gray;
$base-heading-color: $charcoal;
$global-muted-color: $gray;

$base-body-font-family: $global-font-family;
$base-body-font-size: 1.1em;
$base-body-font-weight: 400;
$base-body-line-height: 1.6;
$base-heading-font-weight: 400;
$base-light-font-weight: 300;
$base-h1-font-size-m: 3.2em;
$base-h1-font-size: 2.8em;
$base-h1-line-height: 1.1;
$base-h2-font-size-m: 2.6rem;
$base-h2-font-size: 2.2rem;
$base-h2-line-height: 1.2em;
$base-h3-font-size: 1.65em;
$base-h3-line-height: 1.2;
$base-h4-font-size: 1.225em;
$base-h4-line-height: 1.4;
$base-h5-font-size: 1em;
$base-h5-line-height: 1.4;
$base-h6-font-size: 0.875em;
$base-h6-line-height: 1.4;
$text-lead-font-size: 1.3em;
$text-lead-line-height: 1.5;
$text-lead-color: $gray;
$global-small-font-size: 0.75rem;
//$global-large-font-size: 1.563em;
//$global-small-font-size: 0.8125em;

$base-link-hover-text-decoration: none;
$overlay-primary-background: rgba(34, 34, 34, 0.5);
$overlay-default-background:  linear-gradient(to right, rgba(198,215,66,0.8) 0%, rgba(142,198,63,0.8) 100%);;
$base-em-color: $charcoal;

// Base
$base-link-color: $red;
$base-link-hover-color: darken($red, 10%);

// Background
$background-primary-background: $red;
$background-secondary-background: darken($red, 10%);

// Card
$card-title-font-size: 1.1em;
$card-body-font-size: 1em;
$card-primary-color: $global-color;
$card-primary-title-color: $global-color;
$card-default-color: $global-color;
$card-default-title-color: $black;
$card-default-background: $white;

// Navigation
$navbar-nav-item-font-family: $base-heading-font-family;
$navbar-nav-item-color: $charcoal;
$navbar-nav-item-hover-color: $red;
$navbar-nav-item-active-color: $red;
$navbar-nav-item-font-size: inherit;
$navbar-nav-item-height: 14px;
$navbar-nav-item-active-color: $charcoal;
$navbar-dropdown-nav-item-color: $charcoal;
$inverse-navbar-nav-item-color: $white;
$inverse-navbar-nav-item-hover-color: lighten($yellow, 20%);

// Button
$button-font-size: 0.9em;
$button-line-height: 0;
$button-default-background: $gray;
$button-default-color: $white;
$button-default-hover-background: darken($gray, 10%);
$button-default-hover-color: $white;
$button-primary-background: $red;
$button-primary-color: $white;
$button-primary-hover-background: darken($red, 10%);
$button-primary-hover-color: $white;
$inverse-button-primary-color: $white;
$inverse-button-primary-hover-color: $white;
$inverse-button-primary-background: $yellow;
$inverse-button-primary-hover-background: darken($yellow, 10%);
$inverse-button-primary-active-background: darken($yellow, 10%);

// Align
$align-margin-vertical: 0;

// Dropdown
$navbar-dropdown-background: $white;
$navbar-dropdown-padding: 0;
//$navbar-dropdown-nav-item-hover-color: $white;

// Subnav
$subnav-item-active-color: $red;

// Utility
$height-large-height: 500px;

// Offcanvas
$offcanvas-bar-width: 100%;
$offcanvas-bar-background: $red;

// Padding
$padding-large-padding: 50px;
$padding-large-padding-l: 70px;

// Section
$section-large-padding-vertical-m: 100px;
$section-padding-vertical-m: 70px;
$section-padding-vertical: 30px;
$grid-small-gutter-vertical: 30px;

// Grid
$grid-large-gutter-horizontal: 80px;
$grid-gutter-vertical-l: $section-padding-vertical-m;
$grid-gutter-vertical: $section-padding-vertical;

// Text
$text-primary-color: $red;

// List
$list-margin-top: 0;

// Notifications
$notification-position: 0;
$notification-width: 100%;
$notification-message-success-color: $white;
$notification-message-warning-color: $white;
$notification-message-danger-color: $white;