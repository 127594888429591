.tm-subscription input {
    @extend .uk-input;
    &.tm-link {
        width: auto;
        padding: 0;
        background: transparent;
        color: $red;
        &:hover, &focus {
          color: darken($red, 5%);
        }
    }
}
.paymentSource-form {
    fieldset {
        border: 0;
        padding: 0;
    }
    .item, .multitextrow > input {
        margin: 10px 0;
    }
    input.text {
        height: 40px;
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        width: 100%;
        border: 0 none;
        padding: 0 10px;
        background: #f8f8f8;
        color: #848484;
        line-height: 2.6em;
        -webkit-appearance: none;
        box-sizing: border-box;
        font-size: inherit;
        font-family: inherit;
        line-height: 2.6em;
    }
}
.paypal-rest-form {
    text-align: right;
}
.stripe-charge-form {
    width: 100% !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    fieldset {
        &.card-holder {
            width: 100%;
            input {
                @extend .uk-active;
                @extend .uk-margin;
            }
            button {
                float: right;
                margin-right: 40px;
            }
            &.item {
                margin-bottom: 20px;
            }
        }
        &.card-data {
            max-width: 100%;
            width: 100%;
            border: 0 none;
            color: #848484;
            display: inline-block;
            width: calc(100% - 20px);
            margin-bottom: 20px;
        }
    }
}
.membership-description {
    ul {
        list-style: disc;
        margin-bottom: 16px;
        li {
            strong {
                color: #848484;
            }
        }
    }
}
/* Initially hide the sponsor list */
.toggle-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.8s ease;
}

/* Style the label to display the chevron */
.toggle-label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: bold;
    &:focus {
        background-color: transparent;
    }
}

/* Chevron before toggling */
.chevron {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.4s ease-in-out;
    border: solid #8b2f21;
    border-width: 0 2px 2px 0;
    padding: 2px;
    transform: rotate(45deg); /* Initial downwards pointing chevron */
}

/* When the checkbox is checked, show the content */
.toggle-checkbox:checked + .toggle-label + .toggle-content {
    max-height: 2000px; /* Adjust this based on expected content size */
    transition: max-height 0.5s ease;
}

/* Rotate the chevron when content is visible */
.toggle-checkbox:checked + .toggle-label .chevron {
    transform: rotate(-135deg); /* Upwards pointing chevron */
}