.tm-link-underline {
    display: inline-block;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid $yellow;
    margin-bottom: 0px;
    padding: 0 20px 6px 0!important;
    transition: padding 0.8s, color 1s;
    color: $charcoal;
    font-weight: 500;
    font-size: 0.985em;
    .uk-light & {
        color: $white;
        &:hover, &:focus {
            color: $white;
        }
    }
    .tm-carousel & {
        border-bottom: 2px solid $white;
        &:hover, &:focus {
            padding: 0 0 2px !important;
            color: $white;
        }
    }
    .uk-text-center & {
        padding: 0 20px 2px !important;
        transition: padding 1s, color 1s;
        &:hover, &:focus {
            padding: 0 0 2px !important;
            color: $yellow;
        }
    }
    &:hover, &:active, &:focus {
        padding: 0 0 6px !important;
        color: $yellow;
        outline: 0;
    }
}
.tm-form-link {
    border: 0;
    padding: 0;
    text-decoration: underline;
}