@mixin hook-navbar-misc() {
    .uk-navbar-nav {
        display: block;
        width: 100%;
        @media screen and (min-width: $breakpoint-medium) {
           display: flex;
           width: auto;
        }
        > li {
            margin: 0 20px;
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
            a {
                height: auto !important;
            }
        }
        .tm-primary-navigation & {
            > li {
                border-bottom: 2px solid transparent;
                border-top: 2px solid transparent;
                &.button-link {
                    border-bottom: 0;
                }
                &.social-link {
                    margin: 0;
                }
                &.uk-active {
                    border-bottom: 2px solid $yellow;
                    &.button-link {
                        border-bottom: 0;
                        border-top: 0;
                    }
                    &.social {
                        margin: 0;
                    }
                }
            }
        }
        &.tm-tertiary {
            font-size: 1em;
            height: 16px;
            margin-top: 10px;
            .social-links {
                li {
                    &:first-child {
                        border-right: 1px solid $gray;
                        padding-right: 8px;
                    }
                    line-height: 0.9;
                    border-left: 0;
                    a {
                        padding: 0;
                        svg {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            } 
            > li {
                color: $gray;
                font-family: $base-heading-font-family;
                font-size: 0.65em;
                border-left: 1px solid $gray;
                padding: 0 10px;
                margin: 0;
                &:first-child {
                    border-left: 1px solid transparent;
                    a {
                        padding: 0;
                    }
                }
                &:last-child {
                    padding: 0 0 0 10px;
                }
                a {
                    color: $gray;
                    &:hover, &:focus, &:active {
                        color: $gray;
                    }
                }
                .uk-search-icon {
                    svg {
                        height: 16px;
                    }
                }
            }
        }
        &.tm-footer-nav {
            li {
                font-size: 0.938em;
                margin: 8px 8px;
                width: 100%;
                text-align: center;
                position: relative;
                padding-bottom: 6px;
                &:last-child {
                    &:after {
                        content: none;
                    }
                }
                &:after {
                    content: "";
                    display: inline-block;
                    background: white;
                    width: 8px;
                    bottom: 0px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    height: 1px;
                }
                @media (min-width: $breakpoint-medium) {
                    margin: 0 8px;
                    width: auto;
                    &:after {
                        content: none;
                    }
                }
                a {
                    padding: 0 0px;
                    display: inline-block;
                    font-size: 0.8em;
                    color: $white;
                    @media (min-width: $breakpoint-medium) {
                        display: block;
                    }
                    &.uk-button {
                        display: inline-block;
                        padding: 10px 16px 6px !important;
                        @media (min-width: $breakpoint-medium) {
                            display: block;
                        }
                    }
                }
            }
        }
        &.tm-privacy-nav {
            > li {
                font-size: 0.825em;
                margin: 6px 6px;
                width: 100%;
                padding: 0 10px;
                margin: 0;
                border-left: 0;
                @media (min-width: $breakpoint-medium) {
                    margin: 0;
                    width: auto;
                    border-left: 1px solid $white;
                    &:first-child {
                        border-left: 1px solid transparent;
                        a {
                            padding: 0;
                        }
                    }
                }
                a {
                    padding: 0 0px;
                    display: inline-block;
                    font-family: $alternatve-font-family;
                    text-transform: uppercase;
                    color: $white;
                    line-height: 2;
                    @media (min-width: $breakpoint-medium) {
                        display: block;
                    }
                }
            }
        }
    }
    .uk-navbar-nav > li > a,
    .uk-navbar-item,
    .uk-navbar-toggle {
        height: auto;
        letter-spacing: 1px;
        padding: 0;
        &.uk-button {
            padding: 14px 32px 10px!important;
            line-height: 1.15em !important;
            border-bottom: 0px;
            &:hover, &:focus, &:active { 
                border-bottom: 0px;
            }
        }
        /*.tm-tertiary & {
            margin: 0;
            padding: 0 0 0 10px;
            line-height: 0.9;
        }*/
    }
    .uk-navbar-center, .uk-navbar-center-left>*, .uk-navbar-center-right>*, .uk-navbar-left, .uk-navbar-right {
        align-items: baseline;
    }
    /*.uk-navbar-nav {
        > li {
            &.uk-active {
                a {
                    border-bottom: 0;
                    @media screen and (min-width: $breakpoint-medium) {
                        border-bottom: 1px solid #ffffff;
                    }
                }
            }
            .tm-children {
                li {
                    a {
                        border-bottom: 1px solid transparent;
                        &:hover, &:active, &:focus {
                            border-bottom: 1px solid #ffffff;
                        }
                    }
                    &.uk-active {
                        a {
                            border-bottom: 0;
                            @media screen and (min-width: $breakpoint-medium) {
                                border-bottom: 1px solid #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }*/
}
@mixin hook-button() {
    border-radius: 3px;
    transition-timing-function: ease-in;
    transition: 0.3s;
    letter-spacing: 1px;
    font-family: $base-heading-font-family;
    padding: 22px 14px 18px!important;
    &:hover, &:focus {
        transition-timing-function: ease-out;
        transition: 0.35s;
    }
}
@mixin hook-button-misc() {
    .uk-button-city {
        background: #8b2f21;
        color: $white;
        &:hover, &:focus {
            background: darken(#8b2f21, 5%);
            color: $white;
        }
    }
    .uk-button-woodlawn {
        background: #eac246;
        color: $white;
        &:hover, &:focus {
            background: darken(#eac246, 5%);
            color: $white;
        }
    }
    .uk-button-cemetery {
        background: #809663;
        color: $white;
        &:hover, &:focus {
            background: darken(#809663, 5%);
            color: $white;
        }
    }
    .uk-button-upriver {
        background: #58a3af;
        color: $white;
        &:hover, &:focus {
            background: darken(#58a3af, 5%);
            color: $white;
        }
    }
    .uk-button-clifton {
        background: #8374a2;
        color: $white;
        &:hover, &:focus {
            background: darken(#8374a2, 5%);
            color: $white;
        }
    }
    .uk-button-top {
        background: #f9d55a;
        color: $white;
        &:hover, &:focus {
            background: darken(#f9d55a, 5%);
            color: $white;
        }
    }
    .uk-button-holy {
        background: #cacf75;
        color: $white;
        &:hover, &:focus {
            background: darken(#cacf75, 5%);
            color: $white;
        }
    }
    .uk-button-under {
        background: #de704d;
        color: $white;
        &:hover, &:focus {
            background: darken(#de704d, 5%);
            color: $white;
        }
    }
    .uk-button-downriver {
        background: #a3b197;
        color: $white;
        &:hover, &:focus {
            background: darken(#a3b197, 5%);
            color: $white;
        }
    }
}
@mixin hook-accordion-content() {
    background: $smoke;
    padding: 20px 26px;
}
@mixin hook-accordion() {
    li {
        border-bottom: 1px solid $smoke;
        padding-bottom: 20px;
        padding-right: 30px;
        position: relative;
        &:first-child {
            border-top: 1px solid $smoke;
            padding-top: 20px;
        }
    }
}
@mixin hook-slideshow-misc() {
    .uk-slideshow {
        padding-top: 0;
        @media screen and (min-width: $breakpoint-medium) {
            padding-top: 0;
        }
        /*&.tm-carousel {
            .uk-slideshow-items {
                min-height: 100%;
                @media screen and (min-width: $breakpoint-medium) {
                    min-height: 100%;
                }
            }
        }*/
    }
    .uk-slideshow-items {
        max-height: 680px;
    }
}
@mixin hook-section-misc(){
    .uk-section {
        box-sizing: border-box; /* 1 */
        padding-top: 0;
        padding-bottom: $section-padding-vertical;
        &.uk-has-background, &.uk-section-muted {
            padding-top: $section-padding-vertical !important;
            margin-bottom: $section-padding-vertical;
        }
        &:first-child {
            padding-top: $section-padding-vertical;
        }
        &:last-child {
            &.uk-has-background, &.uk-section-muted {
                margin-bottom: 0 !important;
            }
        }
    }
    .uk-section::before,
    .uk-section::after {
        content: "";
        display: table;
    }
    .uk-section::after { clear: both; }
    .uk-section > :last-child { margin-bottom: 0; }
    .uk-section-xsmall {
        padding-top: 0;
        padding-bottom: $section-xsmall-padding-vertical;
    }
    .uk-section-small {
        padding-top: 0;
        padding-bottom: $section-small-padding-vertical;
    }
    .uk-section-large {
        padding-top: 0;
        padding-bottom: $section-large-padding-vertical;
        &:first-child {
            padding-top: $section-large-padding-vertical;
        }
        &.uk-has-background, &.uk-section-muted {
            padding-top: $section-large-padding-vertical !important;
            margin-bottom: $section-padding-vertical;
        }
    }
    .uk-section-xlarge {
        padding-top: 0;
        padding-bottom: $section-xlarge-padding-vertical;
        &:first-child {
            padding-top: $section-xlarge-padding-vertical;
        }
        &.uk-has-background, &.uk-section-muted {
            padding-top: $section-xlarge-padding-vertical !important;
            margin-bottom: $section-padding-vertical-m;
        }
    }
    @media (min-width: $breakpoint-medium) {
        .uk-section {
            padding-top: 0;
            padding-bottom: $section-padding-vertical-m;
            &.uk-has-background, &.uk-section-muted {
                padding-top: $section-padding-vertical-m !important;
                margin-bottom: $section-padding-vertical-m;
            }
            &:first-child {
                padding-top: $section-padding-vertical-m;
            }
            &:last-child {
                &.uk-has-background, &.uk-section-muted {
                    margin-bottom: 0 !important;
                }
            }
        }
        .uk-section-large {
            padding-top: 0;
            padding-bottom: $section-large-padding-vertical-m;
            &:first-child {
                padding-top: $section-large-padding-vertical-m;
            }
            &.uk-has-background, &.uk-section-muted {
                padding-top: $section-large-padding-vertical-m !important;
                margin-bottom: $section-padding-vertical-m;
            }
        }
        .uk-section-xlarge {
            padding-top: 0;
            padding-bottom: $section-xlarge-padding-vertical-m;
            &:first-child {
                padding-top: $section-xlarge-padding-vertical-m;
            }
            &.uk-has-background, &.uk-section-muted {
                padding-top: $section-xlarge-padding-vertical-m !important;
                margin-bottom: $section-padding-vertical-m;
            }
        }
    }
}
@mixin hook-base-h6() {
    font-family: 'balboa', sans-serif;
    letter-spacing: 1px;
}
@mixin hook-text-lead() {
    font-family: $global-font-family;   
}
@mixin hook-navbar-dropdown() {
    box-shadow: 0 4px 4px $shadow;
}
@mixin hook-navbar-dropdown-nav-item() {
    padding: 12px 10px;
}
@mixin hook-navbar-dropdown-nav-item-hover() {
    background: darken($smoke, 5%);
}
@mixin hook-navbar-dropdown-nav() {
    li {
        &.uk-active {
            background: $smoke;
        }
    }
}
@mixin hook-card-default() {
    box-shadow: 0px 0px 8px $shadow;
}
@mixin hook-card-body() {
    p {
        font-family: $global-font-family;
        font-size: 0.9em;
    }
}
@mixin hook-modal-dialog() {
    border-radius: 6px;
}
@mixin hook-modal-header() {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 20px 50px 13px !important;
}
@mixin hook-modal-close-default() {
    border-top-right-radius: 6px;
}
@mixin hook-modal-body() {
    padding: 50px;
}
@mixin hook-slider-misc() {
    .uk-slider-items {
        padding: 20px 0 !important;
    }
}
@mixin hook-link-misc() {
    .uk-link {
        background: transparent;
    }
}
@mixin hook-list-misc() {
    .uk-list {
        font-size: 1em;
        line-height: 1.2em;
        display: inline-block;
    }
}
@mixin hook-form() {
    line-height: 2.6em;
}
@mixin hook-search-misc() {
    .uk-search {
         line-height: 2.6em;
    }
}
@mixin hook-notification-message-success() {
    background: $global-success-background;
}
@mixin hook-notification-message-warning() {
    background: $global-warning-background;
}
@mixin hook-notification-message-danger() {
    background: $global-danger-background;
}
@mixin hook-notification() {
    text-align: center;
}
@mixin hook-list-misc() {
    .uk-list-large.uk-list-divider > li:nth-child(n+2) {
        padding-top: 24px !important;
    }
}
@mixin hook-form-misc() {
    .uk-form {
        select {
            @extend .uk-select;
        }
        fieldset {
            border: 0;
            margin: 0;
            padding: 0;
        }
        button[type=submit] {
            @extend .uk-button;
            @extend .uk-button-primary;
        }
        .field {
            @extend .uk-margin;
        }
    }
}