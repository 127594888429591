.tm-stories-listing {
	.tm-stories-controls {
		height: 44px;
		a {
			background: $red;
		    color: $white;
		    padding: 8px 14px;
		    border-radius: 4px;
		    margin: 0;
		}
	}
	.uk-card {
		 border-radius: 8px;
		 a {
		 	display: inline-block;
    		height: 100%;
		 }
		 img {
		 	 border-top-left-radius: 8px;
		 	  border-top-right-radius: 8px;
		 }
	}
}
.overlap-slider {
    .position-right {
        right: -30px;
		position: relative;
		@media screen and (min-width: $breakpoint-medium) {
	        right: -30px;
	    	position: absolute;
	    	top: 50%;
	    	transform: translateY(-50%);
		    overflow: hidden;
		    padding-left: 10px;
	    }
    }
}