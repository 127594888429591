.search-bar {
    background: $red;
    .uk-search-input {
        color: $white;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $white;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $white;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $white;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $white;
        }
    }
    .tm-search-close {
        position: absolute;
        margin: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $white;
    }
}

.tm-nav-overlay {
    display: none;
}
.tm-menu-container {
    position: fixed;
    right: 25px;
    top: 25px;
    height: 32px;
    width: 32px;
    cursor: pointer;
    z-index: 100;
    transform: rotate(0);
    transition: 0.25s ease-in-out;
    &:hover {
        opacity: .7;
    }
    &.active {
        #tm-open-menu {
            span {
                position: absolute;
                width: 0;
                &:nth-of-type(1) {
                    transition-delay: 0s;
                }
                &:nth-of-type(2) {
                    top: 9px;
                    transition-delay: 0.15s;
                }

                &:nth-of-type(3) {
                    top: 18px;
                    transition-delay: 0.3s;
                }
            }
        }
        #tm-close-menu {
            span {
                &:nth-of-type(1) {
                    height: 100%;
                    transition-delay: 0.45s;
                }
                &:nth-of-type(2) {
                    width: 100%;
                    transition-delay: 0.6s;
                }
            }
        }
    }
    #tm-open-menu, #tm-close-menu {
        position: absolute;
        top: 0;
        right: 0;
        span {
            border-radius: 5px;
        }
    }
    #tm-open-menu {
        height: 22px;
        width: 32px;
        margin-top: 5px;
        span {
            background: $white;
            border: none;
            height: 2px;
            width: 100%;
            position: relative;
            cursor: pointer;
            display: block;
            margin: 0 0 8px 0;
            transition: width 0.25s ease-in-out;
            &:nth-of-type(1) {
                transition-delay: 0.6s;
            }
            &:nth-of-type(2) {
                transition-delay: 0.45s;
            }

            &:nth-of-type(3) {
                transition-delay: 0.3s;
            }
        }
    }
    #tm-close-menu {
        height: 32px;
        width: 32px;
        transform: rotate(45deg);
        span {
            display: block;
            background: $white;
            &:nth-of-type(1) {
                width: 2px;
                height: 0;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -1px;
                transition: height 0.25s ease-in-out;
                transition-delay: 0.15s;
            }
            &:nth-of-type(2) {
                height: 2px;
                width: 0;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -1px;
                transition: width 0.25s ease-in-out;
                transition-delay: 0s;
            }
        }
    }
}
.tm-nav-overlay {
    position: fixed;
    background: $red;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.6s ease-in-out;
    overflow: hidden;
    z-index: 99;
    max-width: 260px;
    padding: 0;
    &.open {
        opacity: .9;
        visibility: visible;
        height: 100%;
        nav {
            &:before, &:after {
                height: 100%;
            }
        }
        li {
            animation: fadeInRight .5s ease forwards;
            animation-delay: .35s;
            &:nth-of-type(2) {
                animation-delay: .4s;
            }
            &:nth-of-type(3) {
                animation-delay: .45s;
            }
            &:nth-of-type(4) {
                animation-delay: .50s;
            }
        }
    }
    nav {
        position: relative;
        font-family: $base-heading-font-family;
        font-size: $base-h2-font-size;
        font-weight: 400;
        text-align: center;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        &:before, &:after {
            content: '';
            width: 1px;
            height: 0;
            background: #ffffff;
            position: absolute;
            left: 50%;
            margin-left: -2px;
            transition: height 0.25s ease-in-out;
        }
        &:before {
            bottom: 100%;
        }
        &:after {
            top: 100%;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        position: relative;
        height: 100%;
        li {
            display: block;
            position: relative;
            opacity: 0;
            margin-bottom: 16px;
            &:first-child {
                margin-top: 100px;
            }
            a {
                display: block;
                position: relative;
                color: $charcoal;
                text-decoration: none;
                overflow: hidden;
                font-size: 1.6rem;
            }
        }
    }
}
.nav-overlay {
    font-size: 2em;
    font-family: $base-heading-font-family;
    font-weight: 700;
    line-height: 1;
    border: 0;
    color: $white;
}
.tm-mobile-nav-container {
    padding: 10px 0;
    .tm-mobile-logo {
        padding: 0;
        margin: 0;
        margin-right: 30px;
        max-width: 150px;
        .tm-primary-navigation & {
            padding: 10px 0;
            margin-right: 0;
        }
        &:hover, &:focus, &:active {
            color: $white;
        }
    }
    .uk-icon {
        padding: 0;
    }
}

.nav-brand-full {
    width: 100%;
    max-width: 260px;
}
.nav-footer-brand-full {
    width: 100%;
    max-width: 220px;
}
.tm-primary-navigation {
    padding: 0px;
    @media (min-width: $breakpoint-medium) {
        padding: 0px 0px 20px;
    }
    .tm-logo-centered {
        .uk-logo {
            display: block;
        }
    }
    
    li {
        font-size: 0.938em;
        line-height: 1.1;
        a {
            &.uk-button {
                color: $white;
                line-height: 1.6em;
            }
        }
    }
    .tm-children {
        padding: 0 15px;
        margin-top: 10px;
        li {
            a {
                padding: 3px 0;
                color: $white;
                font-family: $base-heading-font-family;
                line-height: 1.25;
                &:hover, &:focus, &:active {
                    color: $white;
                }
            }
        }
    }
}
.tm-footer-wrapper {
    float: none;
    @media (min-width: $breakpoint-medium) {
       float: right;
    }
}
.tm-footer-logo {
    margin: 0 auto;
    @media (min-width: $breakpoint-medium) {
        margin: 0;
    }
}
.tm-copyright {
    text-align: center;
    @media (min-width: $breakpoint-medium) {
        text-align: left;
    }
}
.tm-breadcrumb-wrapper {
    padding: 10px 0;
}
.tm-subnav-title {
    margin-right: 18px;
}
.tm-subnav-list {
    display: inline-flex;
    font-family: $alternatve-font-family;
    text-transform: uppercase;
}