.map-container {
	background: $gray;
}
#map {
	height: 80vh;
	max-width: 2200px;
    margin: 0 auto;
    #zoom {
    	right: 20px !important;
    }
}
.district-description {
	font-size: 0.85em;
    line-height: 1.8em;
}
.district-name {
	padding-right: 30px;
}