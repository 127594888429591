@font-face {
    font-family: 'besley';
    src: url('../fonts/besley-book-webfont.woff2') format('woff2'),
         url('../fonts/besley-book-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'besley';
    src: url('../fonts/besley-bookitalic-webfont.woff2') format('woff2'),
         url('../fonts/besley-bookitalic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}